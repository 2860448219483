import styled from 'styled-components';

export const Content = styled.article`
  text-align: left;
  padding: 6rem 0;
  position: relative;
  z-index: 100;
`;

export const PageTitle = styled.h1`
  margin: 2rem 0 1rem;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  margin: 4rem 0 1rem;
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.color.black.regular};
  ${(props) => props.theme.font_size.xsmall};
  max-width: 100%;

  @media (min-width: ${(props) => props.theme.screen.xs}) {
    max-width: 90%;
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    max-width: 80%;
    ${(props) => props.theme.font_size.small};
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    max-width: 70%;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    max-width: 60%;
  }
`;

export const List = styled.ul`
  margin-bottom: 2rem;
`;

export const Item = styled.li`
  color: ${(props) => props.theme.color.black.regular};
  ${(props) => props.theme.font_size.xsmall};
  margin-bottom: 1rem;
  max-width: 100%;

  @media (min-width: ${(props) => props.theme.screen.xs}) {
    max-width: 90%;
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    max-width: 80%;
    ${(props) => props.theme.font_size.small};
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    max-width: 70%;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    max-width: 60%;
  }
`;

export const Element = styled.div`
  color: ${(props) => props.theme.color.black.regular};
  max-width: ${(props) => (props.overflow ? '100%' : '100%')};
  margin-bottom: 2rem;

  @media (min-width: ${(props) => props.theme.screen.xs}) {
    max-width: ${(props) => (props.overflow ? '100%' : '90%')};
    margin-left: ${(props) => (props.overflow ? '-5%' : '0')};
  }

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    max-width: ${(props) => (props.overflow ? '90%' : '80%')};
    margin-left: ${(props) => (props.overflow ? '-5%' : '0')};
    padding-left: ${(props) => (props.portrait ? '5%' : 'initial')};
    padding-right: ${(props) => (props.portrait ? '5%' : 'initial')};
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    max-width: ${(props) => (props.overflow ? '80%' : '70%')};
    margin-left: ${(props) => (props.overflow ? '-5%' : '0')};
    padding-left: ${(props) => (props.portrait ? '10%' : 'initial')};
    padding-right: ${(props) => (props.portrait ? '10%' : 'initial')};
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    max-width: ${(props) => (props.overflow ? '80%' : '60%')};
    margin-left: ${(props) => (props.overflow ? '-10%' : '0')};
    padding-left: ${(props) => (props.portrait ? '15%' : 'initial')};
    padding-right: ${(props) => (props.portrait ? '15%' : 'initial')};
  }
`;

export const LearningsGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px;
`;

export const Learning = styled.div`
  flex-basis: 50%;
  padding-right: 24px;

  @media (min-width: ${(props) => props.theme.screen.sm}) {
    flex-basis: 33%;
    padding-right: 24px;
  }

  @media (min-width: ${(props) => props.theme.screen.md}) {
    flex-basis: 25%;
    padding-right: 36px;
  }

  @media (min-width: ${(props) => props.theme.screen.lg}) {
    flex-basis: 15%;
    padding-right: 48px;
  }
`;

export const LearningTopic = styled.h5`
  margin: 0;
  margin-bottom: 36px;
`;
