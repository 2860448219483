import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Img from 'gatsby-image';

import { Element } from '@components/pages';

const Description = styled.small`
  text-align: center;
  display: block;
  margin-top: 0.5rem;
  line-height: 1.25;
  padding: ${props => (props.overflow ? '0 15%' : '0 10%')};
  color: ${props => props.theme.color.black.lighter};
`;

const Image = ({ edges, name, alt, description, overflow, portrait }) => (
  <Element overflow={overflow} portrait={portrait}>
    <Img
      fluid={
        edges.find(({ node }) => node.name === name).node.childImageSharp.fluid
      }
      alt={alt}
    />
    {description && (
      <Description overflow={overflow}>{description}</Description>
    )}
  </Element>
);

Image.propTypes = {
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  description: PropTypes.string,
  overflow: PropTypes.bool,
};

Image.defaultProps = {
  description: undefined,
  overflow: false,
};

export default Image;
