import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// Learnings and tools
// Machine Learning, NLP, Python, Google Cloud Functions, React
import { ReactComponent as MachinelearningIcon } from './svg/machinelearning.svg';
import { ReactComponent as NLPIcon } from './svg/nlp.svg';
import { ReactComponent as PythonIcon } from './svg/python.svg';
import { ReactComponent as GoogleIcon } from './svg/google.svg';
import { ReactComponent as ReactIcon } from './svg/react.svg';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Image from '@common/Image';
import ExternalLink from '@common/ExternalLink';
import DemoLink from '@common/DemoLink';
import { PageSEO } from '@common/SEO';

import {
  Section,
  Container,
  DesignText,
  // TechnologyText,
  // RunningText,
} from '@components/global';

import {
  Content,
  PageTitle,
  Title,
  Paragraph,
  List,
  Item,
  LearningsGrid,
  Learning,
  LearningTopic,
  // Element,
} from '@components/pages';

import Footer from '@sections/Footer';

import meta from './emotions-in-tweets.json';
const { title, description } = meta;

const PortfolioPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        designs: allFile(
          filter: { absolutePath: { regex: "/emotions-in-tweets/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 900, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        seo: allFile(
          filter: {
            absolutePath: { regex: "/emotions-in-tweets/" }
            name: { eq: "thumbnail" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <PageSEO
          title={title}
          description={description}
          img={data.seo.edges[0].node.childImageSharp.fixed.src}
          location={location}
        />
        <Navbar page />

        <Section>
          <Container>
            <Content>
              <PageTitle>
                <DesignText>{title}</DesignText>
              </PageTitle>
              <Paragraph>{description}</Paragraph>
              <Paragraph>
                <DemoLink href="https://emotions.aitorperez.com/">
                  Discover some emotions!
                </DemoLink>
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="thumbnail"
                alt="The prototype website"
                overflow
              />

              <LearningsGrid>
                <Learning>
                  <MachinelearningIcon />
                  <LearningTopic>Machine Learning</LearningTopic>
                </Learning>
                <Learning>
                  <NLPIcon />
                  <LearningTopic>
                    Natural Language Processing (NLP)
                  </LearningTopic>
                </Learning>
                <Learning>
                  <PythonIcon />
                  <LearningTopic>Python</LearningTopic>
                </Learning>
                <Learning>
                  <GoogleIcon />
                  <LearningTopic>Google Cloud Functions</LearningTopic>
                </Learning>
                <Learning>
                  <ReactIcon />
                  <LearningTopic>React</LearningTopic>
                </Learning>
              </LearningsGrid>

              <Paragraph>
                On a capstone project for an artificial intelligence course I
                wanted to get a practical experience with:
              </Paragraph>
              <List>
                <Item>Programming a multilayer perceptron (MLP)</Item>
                <Item>
                  Using a publicly available datasetfor training and testing
                </Item>
                <Item>Solving a multi-label classification task</Item>
                <Item>Putting the AI system to use</Item>
              </List>
              <Paragraph>
                After doing some research (mainly about Natural Language
                Processing, due to personal interest) I discovered 2 things that
                caught my attention:
              </Paragraph>
              <List>
                <Item>
                  <ExternalLink href="https://github.com/facebookresearch/LASER">
                    LASER
                  </ExternalLink>
                  , an AI system from facebook that maps sentences to a
                  1024-dimensional space with the additional benefit that
                  sentences of similar meaning across 93 languages get mapped
                  nearby on the embedding space
                </Item>
                <Item>
                  A dataset of tweets for sentiment analysis which includes
                  annotations for intensity (regression and ordinal
                  classification for 4 emotions), polarity (regression and
                  ordinal classification), presence or absence of emotion
                  (multi-labelling of 11 emotions)
                </Item>
              </List>
              <Paragraph>
                I thought it would be interesting to try to put together a
                machine learning system capable of detecting emotion in any
                language, and I built it. Then I designed a website{' '}
                <b>to interact with it and showcase the prototype</b>.
              </Paragraph>

              <Title>Product objectives</Title>
              <Paragraph>The application must:</Paragraph>
              <List>
                <Item>Explain clearly the process</Item>
                <Item>Demonstrate the technology</Item>
                <Item>Let the user have fun with this AI</Item>
              </List>

              <Title>Features and functions</Title>
              <Paragraph>
                Possible features that could be designed for the user:
              </Paragraph>
              <List>
                <Item>
                  Input arbitrary short texts and have them analysed by the AI
                </Item>
                <Item>Choose a short text from a predefined list of text</Item>
                <Item>Retrieve short text from a third party service</Item>
              </List>

              <Paragraph>
                Given that the machine learning system was trained with a
                dataset of tweets, and that Twitter provides a public API to
                access its content, the natural decision was to select{' '}
                <ExternalLink href="https://twitter.com/">Twitter</ExternalLink>{' '}
                as a source of content (but it could have been any other
                source).
              </Paragraph>
              <Paragraph>
                A side-benefit was that tweets are restricted in length,
                limiting the number of sentences of the text (and that has a
                direct impact on the resources needed to run the algorithm,
                which is currently deployed on a free tier of cloud computing).
              </Paragraph>
              <Paragraph>
                Arbitrary text was discarded for the same reasons of length, and
                to avoid having to implement security over arbitrary inputs.
              </Paragraph>

              <Title>Flow</Title>
              <Paragraph>
                To be as easy to get started as possible, the application
                immediately gets a random example when the user lands on the
                website and runs the algorithm without user intervention. From
                then, the user should be able to:
              </Paragraph>
              <List>
                <Item>
                  See the <b>results</b> and <b>inspect the process</b>
                </Item>
                <Item>
                  <b>Paste a new tweet URL</b> and try again
                </Item>
                <Item>
                  <b>Select another predefined example</b> and try again
                </Item>
                <Item>
                  Get to the documentation to <b>learn how it works</b> under
                  the hood
                </Item>
              </List>

              <Title>Interface elements</Title>
              <Paragraph>
                The best way to explain how an artificial intelligence system
                works is by trying to break down its process in small,
                easy-to-understand steps that can be presented to the user. Then
                visualize them and their outputs.
              </Paragraph>
              <Paragraph>
                When the user selects a tweet (either pasting its link or using
                one of the preloaded examples) its full raw{' '}
                <b>text is retrieved</b>. An element to display the tweet’s
                contents is needed.
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="tweet"
                alt="Sketch of the tweet component"
                overflow
              />
              <Paragraph>
                This text is then sent to a server that{' '}
                <b>breaks it down into sentences</b>. A visual representation of
                the sentences is needed.
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="sentences"
                alt="Sketches of the breakdown into sentences"
                overflow
              />
              <Paragraph>
                The server transforms each of this sentences to vectors of 1024
                values. These values represent the "meaning" of the sentence
                independent of the language: "I love you" and "Je t'aime" get
                almost the same 1024 values. These are the sentence embeddings.
                This is an internal process that would do more harm than good to
                the user if visualized.
              </Paragraph>
              <Paragraph>
                Next, the server uses this values as inputs of the{' '}
                <b>11 neural networks</b> that we have trained for each specific
                task and results in a <b>probability</b> for each one of the{' '}
                <b>11 emotions being present</b> on each sentence. Visual
                indicators for each singular emotion are needed.
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="emotion-display"
                alt="Sketches of the display of emotion indicators"
                overflow
              />
              <Paragraph>
                Finally, the emotions detected with confidence{' '}
                <b>above a certain threshold</b> are predicted for this tweet. A
                summary with these findings is needed.
              </Paragraph>

              <Title>Visual treatment</Title>
              <Paragraph>
                The application emulates an <b>embedded tweet</b> and adopts{' '}
                <b>Twitter’s palette</b> to establish a clear visual
                relationship.
              </Paragraph>
              <Image
                edges={data.designs.edges}
                name="mobile"
                alt="The application on a mobile device"
                overflow
              />
              <Image
                edges={data.designs.edges}
                name="desktop"
                alt="The application on a desktop browser"
                overflow
              />
            </Content>
          </Container>
        </Section>

        <Footer neutral />
      </Layout>
    )}
  />
);

export default PortfolioPage;
