import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { AwesomeButton } from 'react-awesome-button';
import 'react-awesome-button/dist/styles.css';
import './demo-link.css';

const DemoLink = ({ regular, href, children, ...other }) => (
  <>
    <OutboundLink
      regular={regular}
      href={href}
      {...other}
      rel="noreferrer noopener"
      target="_blank"
    >
      <AwesomeButton type="link" ripple>
        {children} 🚀
      </AwesomeButton>
    </OutboundLink>
  </>
);

export default DemoLink;
